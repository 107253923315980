import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import _ from "lodash";

import SocialButton from "./auth/SocialButton";
import MessageToLogIn from "./MessageToLogIn";
import CustomDropModal from "../components/PopUp";

import * as authActions from "../store/auth/actions";
import * as authSelectors from "../store/auth/selectors";
import * as exceptionsSelectors from "../store/exceptions/selectors";
import * as exceptionsActions from "../store/exceptions/actions";
import * as categoriesActions from "../store/categories/actions";
import * as categoriesSelectors from "../store/categories/selectors";
import * as settingsSelectors from "../store/settings/selectors";
import * as settingsActions from "../store/settings/actions";

import {
  makePATHUrl,
  checkCookieConsent,
  viewModals,
  loadIdentifier,
} from "../helpers";
import strings from "../services/strings";
import auth from "../services/auth";
import language from "../services/language";

import "./Navbar.scss";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      userLoaded: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        password_confirmation: "",
        share_draft_id: "",
        currentLanguage: language.get(),
        otp: "",
        remember_me: false,
        identifier: loadIdentifier(),
        token: "",
      },
      otpSent: false,
    };
  }
  listeners = {};

  componentDidUpdate() {
    _.delay(() => {
      if (localStorage.getItem("userUpdated") === "true") {
        this.setState({ user: auth.getLocalUser() });
        localStorage.setItem("userUpdated", false);
      }
    }, 250);
    if (this.props.currentModal === "cookieConsentModal") {
      this.refs.cookieConsentModal.show();
    }
  }

  componentDidMount() {
    this.listeners.onMouseUp = (e) => {
      this.setState({ showDropdown: false });
    };
    // ark/1-password-forgotten
    const reset = window.location.pathname
    if(reset.search('resetPassword') !== -1){
        const paths = reset.split('/')
        const datas = {
          token: paths[paths.length-1],
          email: window.location.search.replace('?email=',"")
        }
        this.setState({
          form: _.extend(this.state.form, datas),
        });
        this.toggleModal(null, 'reset')
    }

    this.props.fetchHeaderArticleCategories();

    window.addEventListener("mouseup", this.listeners.onMouseUp);
    _.delay(() => {
      if (!auth.isAuthenticated() && localStorage.getItem("sharedDraftId")) {
        let item = {};
        item["share_draft_id"] = localStorage.getItem("sharedDraftId");
        this.setState({
          form: _.extend(this.state.form, item),
        });
        // this.handleLoginClick()
      } else {
        localStorage.removeItem("sharedDraftId");
      }
    }, 250);
  }

  componentWillUnmount() {
    window.removeEventListener("mouseup", this.listeners.onMouseUp);
  }

  hasError = (inputName) => {
    return !!this.props.exceptions[inputName];
  };

  getErrorClass = (inputName, defaultClasses = "") => {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  };

  getLoginErrors = () => {
    let error = "";
    if (_.size(this.props.exceptions)) {
      if (this.props.exceptions.errors === "user_blocked") {
        error = strings.get("Exceptions.user_blocked");
      } else if (this.props.exceptions.hasOwnProperty("invalid_credentials")) {
        error = strings.get("Exceptions.invalid_credentials");
      } else if (this.props.exceptions.hasOwnProperty("invalid_user")) {
        error = strings.get("Exceptions.invalid_user");
      }
      return <div className="alert alert-danger">{error}</div>;
    }
  };

  getRegisterErrors = () => {
    let error = "";
    if (_.size(this.props.exceptions)) {
      if (
        this.props.exceptions.email &&
        this.props.exceptions.email === strings.get("Exceptions.duplicated")
      ) {
        error = strings.get("Exceptions.email_already_use");
      } else {
        error = strings.get("Exceptions.all_field_required");
      }

      return <div className="alert alert-danger">{error}</div>;
    }
  };

  getForgotErrorsAndResetPasswordErrors = () => {
    let error = [];
    if (_.size(this.props.exceptions)) {
      _.map(this.props.exceptions, (k, v) => {
        error.push(_.upperFirst(v + " " + k));
      });
      let errorMessage = _.join(error, "\n");

      return <div className="alert alert-danger">{errorMessage}</div>;
    }
  };

  handleSocialLogin = (user, err) => {
    if (!err) {
      let params = {
        first_name: user._profile.firstName,
        last_name: user._profile.lastName,
        email: user._profile.email,
        provider: user._provider,
        uid: user._profile.id,
        accessToken: user._token.accessToken,
        expiresAt: user._token.expiresAt,
        profilePicURL: user._profile.profilePicURL,
        share_draft_id: this.state.form.share_draft_id,
      };
      this.props.login(params);
    }
  };

  handleSocialRegister = (user, err) => {
    if (!err) {
      let params = {
        first_name: user._profile.firstName,
        last_name: user._profile.lastName,
        email: user._profile.email,
        provider: user._provider,
        uid: user._profile.id,
        accessToken: user._token.accessToken,
        expiresAt: user._token.expiresAt,
        profilePicURL: user._profile.profilePicURL,
        share_draft_id: this.state.form.share_draft_id,
        // currentLanguage: JSON.parse(localStorage.getItem("language")).id
        currentLanguage: language.get(),
      };
      this.props.register(params, this.handleCancelClick);
    }
  };

  checkUserConsentOnMenuClick = (event) => {
    event.preventDefault();
    if (!checkCookieConsent()) {
      this.props.setCurrentModal("cookieConsentModal");
    } else {
      window.location.replace(event.target.href);
    }
  };

  handlecookieConsentModalHide = () => {
    if (this.props.cookieBannerStatus) {
      this.props.setCookieBannerStatus();
    }
    this.props.setCurrentModal("");
  };

  getLoginForm = () => {
    return (
      <form onSubmit={this.handleLoginSendClick} className="otp-form">
        <h2>{strings.get("Client.loginPage.modalHeader")}</h2>
        <p>{strings.get("Client.loginPage.modalInfo")}</p>
        {this.getLoginErrors()}
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            name="email"
            placeholder={strings.get("Client.loginPage.email")}
            value={this.state.form.email}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            type="password"
            name="password"
            placeholder={strings.get("Client.loginPage.password")}
            value={this.state.form.password}
            onChange={this.handleInputChange}
          />
          <div className="help-block other-actions">
            <div className="pull-right">
              <a
                href="#"
                onClick={(e) => {
                  this.toggleModal(e, "forgot");
                }}
              >
                {strings.get("App.forgotModal.forgotPassword")}
              </a>
            </div>
          </div>
        </div>
        <div className="form-actions">
          <input
            type="hidden"
            name="share_draft_id"
            value={this.state.form.share_draft_id}
          />
          <button type="submit" className="btn btn-primary">
            {strings.get("Client.loginPage.login")}
          </button>

          <SocialButton
            siteSettings = {this.props.siteSettings}
            handleSocial={this.handleSocialLogin}
            fbBtnLabel={strings.get("Client.loginPage.facebook")}
            googleBtnLabel={strings.get("Client.loginPage.google")}
            linkedinBtnLabel={strings.get("Client.loginPage.linkedin")}
            handleLinkedin={this.props.getLinkedInDetails}
          />

          <button
            className="btn btn-default"
            onClick={(e) => {
              this.toggleModal(e, "register");
            }}
          >
            {strings.get("Client.registerPage.backToRegister")}
          </button>
        </div>
      </form>
    );
  };

  handleRememberMeChange = (e) => {
    let form = {};
    form[e.target.name] = e.target.checked ? true : false;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  };

  getOtpForm = () => {
    return (
      <form onSubmit={this.handleOtpSendClick}>
        <h2>{strings.get("Client.loginPage.otp.title")}</h2>
        <p>
          {`${strings.get("Client.loginPage.otp.subTitle")} ${
            this.state.form.email
          }`}
        </p>

        <div className="form-group">
          <input
            className="form-control"
            type="number"
            name="otp"
            placeholder={strings.get("Client.loginPage.otp.inputPlaceholder")}
            value={this.state.form.otp}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="form-group remember-me">
          <input
            type="checkbox"
            name="remember_me"
            value={this.state.form.remember_me}
            onChange={this.handleRememberMeChange}
          />
          <p>{strings.get("Client.loginPage.otp.rememberMeLabel")}</p>
        </div>
        <div className="form-actions">
          <button type="submit" className="btn btn-primary">
            {strings.get("Client.loginPage.otp.submitButton")}
          </button>
        </div>
      </form>
    );
  };

  getLoginModal = () => {
    return (
      <CustomDropModal
        className="boron-modal form-modal login-modal"
        ref="loginModal"
        onHide={this.handleModalHide}
      >
        {this.state.otpSent ? this.getOtpForm() : this.getLoginForm()}
      </CustomDropModal>
    );
  };

  getForgotModal = () => {
    return (
      <CustomDropModal
        className="boron-modal form-modal"
        ref="forgotModal"
        onHide={this.handleModalHide}
      >
        <h2>{strings.get("App.forgotModal.modalHeader")}</h2>
        <p>{strings.get("App.forgotModal.modalInfo")}</p>
        {this.getForgotErrorsAndResetPasswordErrors()}
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            name="email"
            placeholder={strings.get("App.forgotModal.email")}
            value={this.state.form.email}
            onChange={this.handleInputChange}
          />
          <input type="hidden" name="domain" value={this.getDomain} />
        </div>
        <div className="form-actions">
          <button
            className="btn btn-primary"
            onClick={this.handleForgotSendClick}
          >
            {strings.get("App.forgotModal.sendEmail")}
          </button>
          <button
            className="btn btn-default"
            onClick={(e) => {
              this.toggleModal(e, "register");
            }}
          >
            {strings.get("Client.registerPage.backToRegister")}
          </button>
          <button
            className="btn btn-default"
            onClick={(e) => {
              this.toggleModal(e, "login");
            }}
          >
            {strings.get("Client.loginPage.backToLogin")}
          </button>
          <button className="btn btn-default" onClick={this.handleCancelClick}>
            {strings.get("Client.loginPage.cancel")}
          </button>
        </div>
      </CustomDropModal>
    );
  };


  getResetPasswordModal = () => {
    return (
      <CustomDropModal
        className="boron-modal form-modal fade"
        ref="resetPasswordModal"
        backdrop={true}
        onHide={this.handleModalHide}
      >
        <h2>{strings.get("App.ResetPasswordModal.modalHeader")}</h2>
        <p>{strings.get("App.ResetPasswordModal.modalInfo")}</p>
        {this.getForgotErrorsAndResetPasswordErrors()}
        <div className="form-group">
          <input
            className="form-control"
            type="password"
            name="password"
            placeholder={strings.get("App.ResetPasswordModal.password")}
            value={this.state.form.password}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            type="password"
            name="password_confirmation"
            placeholder={strings.get("App.ResetPasswordModal.confirmPassword")}
            value={this.state.form.password_confirmation}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="form-actions">
          <button
            className="btn btn-primary"
            onClick={this.handleResetPasswordClick}
          >
            {strings.get("App.ResetPasswordModal.save")}
          </button>
          <button
            className="btn btn-default"
            onClick={(e) => {
              this.toggleModal(e, "register");
            }}
          >
            {strings.get("Client.registerPage.backToRegister")}
          </button>
          <button
            className="btn btn-default"
            onClick={(e) => {
              this.toggleModal(e, "login");
            }}
          >
            {strings.get("Client.loginPage.backToLogin")}
          </button>
        </div>
      </CustomDropModal>
    );
  };

  getCookieConsentModal = () => {
    return (
      <CustomDropModal
        className="boron-modal form-modal cookie-modal"
        ref="cookieConsentModal"
        onHide={this.handlecookieConsentModalHide}
      >
        <h2>{strings.get("Client.homePage.cookieConsentModal.modalHeader")}</h2>
        <p>{strings.get("Client.homePage.cookieConsentModal.modalInfo")}</p>
      </CustomDropModal>
    );
  };

  getRegisterModal = () => {
    return (
      <CustomDropModal
        className="boron-modal form-modal registration-modal"
        ref="registerModal"
        onHide={this.handleModalHide}
      >
        <form onSubmit={this.handleRegisterSendClick}>
          <h2>{strings.get("Client.registerPage.modalHeader")}</h2>
          <p>{strings.get("Client.registerPage.modalInfo")}</p>
          {this.getRegisterErrors()}
          <div
            className={
              this.getErrorClass("first_name", "form-group") +
              " col-sm-6" +
              " col-xs-12"
            }
          >
            <input
              className="form-control"
              type="text"
              name="first_name"
              placeholder={strings.get("Client.registerPage.firstName")}
              value={this.state.form.first_name}
              onChange={this.handleInputChange}
            />
          </div>
          <div
            className={
              this.getErrorClass("last_name", "form-group") +
              " col-sm-6" +
              " col-xs-12"
            }
          >
            <input
              className="form-control"
              type="text"
              name="last_name"
              placeholder={strings.get("Client.registerPage.lastName")}
              value={this.state.form.last_name}
              onChange={this.handleInputChange}
            />
          </div>
          <div
            className={this.getErrorClass("email", "form-group") + " col-xs-12"}
          >
            <input
              className="form-control"
              type="text"
              name="email"
              placeholder={strings.get("Client.registerPage.email")}
              value={this.state.form.email}
              onChange={this.handleInputChange}
            />
          </div>
          <div
            className={
              this.getErrorClass("password", "form-group") +
              " col-sm-6" +
              " col-xs-12"
            }
          >
            <input
              className="form-control"
              type="password"
              name="password"
              placeholder={strings.get("Client.registerPage.password")}
              value={this.state.form.password}
              onChange={this.handleInputChange}
            />
          </div>
          <div
            className={
              this.getErrorClass("password", "form-group") +
              " col-sm-6" +
              " col-xs-12"
            }
          >
            <input
              className="form-control"
              type="password"
              name="confirm_password"
              placeholder={strings.get("Client.registerPage.confirmPassword")}
              value={this.state.form.confirm_password}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-actions col-xs-12">
            <input
              type="hidden"
              name="share_draft_id"
              value={this.state.form.share_draft_id}
            />
            <button type="submit" className="btn btn-primary">
              {strings.get("Client.registerPage.register")}
            </button>

            <SocialButton
            siteSettings = {this.props.siteSettings}

              handleSocial={this.handleSocialRegister}
              fbBtnLabel={strings.get("Client.loginPage.facebook")}
              googleBtnLabel={strings.get("Client.loginPage.google")}
              linkedinBtnLabel={strings.get("Client.loginPage.linkedin")}
              handleLinkedin={this.props.getLinkedInDetails}
            />

            <button
              className="btn btn-default"
              onClick={(e) => {
                this.toggleModal(e, "login");
              }}
            >
              {strings.get("Client.loginPage.backToLogin")}
            </button>

            <button
              className="btn btn-default"
              onClick={this.handleCancelClick}
            >
              {strings.get("Client.loginPage.cancel")}
            </button>

            <p className="text-center btn-nostyle">
              {strings.get("Client.registerPage.varifyEmail")}
            </p>
          </div>
        </form>
      </CustomDropModal>
    );
  };

  getUserName = () => {
    if (this.props.profile && !_.isEmpty(this.props.profile)) {
      return `${this.props.profile.first_name} ${this.props.profile.last_name}`;
    }
  };

  getHeaderArticleCategories = () => {
    if (this.props.headerArticleCategories) {
      // let locale = "NL";
      let locale = language.getIso2();
      // if (localStorage.getItem("language")) {
      //   locale = JSON.parse(localStorage.getItem("language")).iso2;
      // }
      let menu = this.props.menuItem;
      return _.map(this.props.headerArticleCategories, (item) => {
        let menuSlug = makePATHUrl(item.slug, item.id);
        return (
          <Link
            key={`navbar-${item.id}`}
            className={menu === menuSlug ? "btn btn-primary" : "btn"}
            to={`/${locale}/articles/${menuSlug}`}
          >
            {item.name}
          </Link>
        );
      });
    }
  };

  getMenu = () => {
    let locale = language.getIso2();
    if (this.props.profile && !_.isEmpty(this.props.profile)) {
      // let userImg = this.props.profile.imageURL ? (
      //   <img src={this.props.profile.imageURL} />
      // ) : (
      //   <i className="ion-person" />
      // );
      return (
        <span className="leftmenu">
          <Link
            className={
              this.props.menuItem === "home" ? "btn btn-primary" : "btn"
            }
            to={`/${locale}/`}
          >
            {strings.get("Client.homePage.home")}
          </Link>
          <Link
            className={
              this.props.menuItem === "categories" ? "btn btn-primary" : "btn"
            }
            to={`/${locale}/documenten`}
            // onClick={this.checkUserConsentOnMenuClick}
          >
            {strings.get("Client.homePage.headerMenu.documents")}
          </Link>
          <Link
            className={
              this.props.menuItem === "workflowCategories"
                ? "btn btn-primary"
                : "btn"
            }
            to={`/${locale}/workflow`}
            // onClick={this.checkUserConsentOnMenuClick}
          >
            {strings.get("Client.homePage.headerMenu.workflows")}
          </Link>
          {this.getHeaderArticleCategories()}
          {/* <a
            className="btn btn-secondary hidden-md hidden-lg mobile-only"
            href="#"
            onClick={this.handleProfileClick}
          >
            {strings.get("Client.homePage.profile")}{" "}
            <i className="ion-android-arrow-dropdown" />
            {this.getDropdown()}

          </a> */}
          <Link
            className={
              this.props.menuItem === "contact" ? "btn btn-primary" : "btn"
            }
            to={`/${locale}/contact`}
          >
            {strings.get("Client.homePage.headerMenu.contact")}
          </Link>
          {/* <a className="btn" href="/" onClick={this.handleLogoutClick}>
            {strings.get("Client.homePage.logout")}
          </a> */}
        </span>
      );
    } else {
      return (
        <span className="leftmenu">
          <Link
            className={
              this.props.menuItem === "home" ? "btn btn-primary" : "btn"
            }
            to={`/${locale}/`}
          >
            {strings.get("Client.homePage.home")}
          </Link>
          <Link
            className={
              this.props.menuItem === "categories" ? "btn btn-primary" : "btn"
            }
            to={`/${locale}/documenten`}
            // onClick={this.checkUserConsentOnMenuClick}
          >
            {strings.get("Client.homePage.headerMenu.documents")}
          </Link>
          <Link
            className={
              this.props.menuItem === "workflowCategories"
                ? "btn btn-primary"
                : "btn"
            }
            to={`/${locale}/workflow`}
            // onClick={this.checkUserConsentOnMenuClick}
          >
            {strings.get("Client.homePage.headerMenu.workflows")}
          </Link>
          {this.getHeaderArticleCategories()}
          <Link
            className={
              this.props.menuItem === "contact" ? "btn btn-primary" : "btn"
            }
            to={`/${locale}/contact`}
          >
            {strings.get("Client.homePage.headerMenu.contact")}
          </Link>
          {/* <a
            className="btn"
            href="javascript:void(0);"
            onClick={this.handleLoginClick}
          >
            {strings.get("Client.homePage.headerMenu.login")}
          </a> */}
          {/* <a
            className="btn"
            href="javascript:void(0);"
            onClick={this.handleRegisterClick}
          >
            {strings.get("Client.homePage.headerMenu.register")}
          </a> */}
        </span>
      );
    }
  };

  getMenuUser = () => {
    let locale = language.getIso2();
    if (this.props.profile && !_.isEmpty(this.props.profile)) {
      return (
        <span className="rightmenu">
          <a
            className="btn btn-secondary"
            href="#"
            onClick={this.handleProfileClick}
          >
            {strings.get("Client.homePage.profile")}{" "}
            <i className="ion-android-arrow-dropdown" />
          </a>
          {this.getDropdown()}
          <a className="hide-mobile">|</a>
          <Link onClick={this.handleLogoutClick} to="/" className="btn">
            {strings.get("Client.homePage.logout")}
          </Link>
        </span>
      );
    } else {
      return (
        <span className="rightmenu">
          <Link className="btn" to="" onClick={this.handleLoginClick}>
            {strings.get("Client.homePage.headerMenu.login")}
          </Link>
          <a className="hide-mobile">|</a>
          <Link className="btn" to="" onClick={this.handleRegisterClick}>
            {strings.get("Client.homePage.headerMenu.register")}
          </Link>
        </span>
      );
    }
  };

  getDropdown = () => {
    let locale = language.getIso2();

    if (this.state.showDropdown) {
      return (
        <ul className="dropdown-menu">
          {/* <li>
            <Link to={`/${locale}/user`}>Overview</Link>
          </li> */}
          <li>
            <Link to={`/${locale}/profile`}>
              {strings.get("Client.profilePage.profile")}
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/drafts`}>
              {strings.get("Client.profilePage.drafts")}
            </Link>
          </li>
          {/* <li>
            <Link to={`/${locale}/roles`}>
              {strings.get("Client.profilePage.roles")}
            </Link>
          </li> */}
          <li>
            <Link to={`/${locale}/shareddrafts`}>
              {strings.get("Client.profilePage.shareddrafts")}
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/myworkflows`}>
              {strings.get("Client.homePage.myWorkflow.title")}
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/mydocuments`}>
              {strings.get("Client.profilePage.downloads")}
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/messages/shareemail`}>
              {strings.get("Client.profilePage.messages")}
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/settings`}>
              {strings.get("Client.profilePage.settings")}
            </Link>
          </li>
          <li>
            <Link onClick={this.handleLogoutClick} to="/">
              {strings.get("Client.homePage.logout")}
            </Link>
            {/* <a href="javascript:void(0);" onClick={this.handleLogoutClick}>
              {strings.get("Client.homePage.logout")}
            </a> */}
          </li>
        </ul>
      );
    }
  };
  

  handleInputChange = (e) => {
    let item = {};
    item[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, item),
    });
  };

  getDomain = () => {
    return language? language.getIso2():'';
  };

  handleModalHide = () => {
    this.props.clearExceptions();
    this.setState({
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        share_draft_id: this.state.form.share_draft_id,
        currentLanguage: language.get(),
      },
    });
  };

  handleProfileClick = (e) => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  handleMouseOver = () => {
    window.removeEventListener("mouseup", this.listeners.onMouseUp);
  };

  handleMouseOut = () => {
    window.addEventListener("mouseup", this.listeners.onMouseUp);
  };

  handleLogoutClick = (e) => {
    e.preventDefault();
    this.setState({ showDropdown: false });
    this.props.logout();
  };

  handleLoginClick = (event) => {
    event.preventDefault();
    this.refs.loginModal.show();
  };

  handleRegisterClick = (event) => {
    event.preventDefault();
    this.refs.registerModal.show();
  };

  handleLoginSendClick = (e) => {
    e.preventDefault();
    if (checkCookieConsent()) {
      this.props.login(this.state.form, this.handleOtpSent);
    } else {
      NotificationManager.warning(
        strings.get("Client.notifications.cookieRequiredOnLogin")
      );
      this.props.setCurrentModal(viewModals.COOKIE_CONSENT);
    }
  };

  handleOtpSendClick = (e) => {
    e.preventDefault();
    this.props.otpSubmit(this.state.form);
  };

  handleOtpSent = () => {
    this.setState({ otpSent: true });
  };

  handleForgotSendClick = () => {
    this.props.forgot(this.state.form);
  };


  handleResetPasswordClick = (e) => {
    if (this.state.form.password !== this.state.form.password_confirmation) {
      NotificationManager.error(
        strings.get("Client.notifications.passwordMismatchOnRegister")
      );
    }
    else  this.props.resetPassword(this.state.form);
  };

  toggleModal = (event, type) => {
   if(event) event.preventDefault();
    if (type === "login") {
      this.refs.registerModal.hide();
      this.refs.forgotModal.hide();
      this.refs.resetPasswordModal.hide();
      this.refs.loginModal.show();
    } else if (type === "forgot") {
      this.refs.loginModal.hide();
      this.refs.resetPasswordModal.hide();
      this.refs.registerModal.hide();
      this.refs.forgotModal.show();
    } else if (type === "reset") {
      this.refs.loginModal.hide();
      this.refs.registerModal.hide();
      this.refs.forgotModal.hide();
      this.refs.resetPasswordModal.show();
      this.refs.resetPasswordModal.refs.refrence.handleBackdropClick = ()=>{}
    } else {
      this.refs.loginModal.hide();
      this.refs.resetPasswordModal.hide();
      this.refs.forgotModal.hide();
      this.refs.registerModal.show();
    }
  };

  handleRegisterSendClick = (e) => {
    e.preventDefault();
    if (checkCookieConsent()) {
      if (this.state.form.password !== this.state.form.confirm_password) {
        NotificationManager.error(
          strings.get("Client.notifications.passwordMismatchOnRegister")
        );
      } else {
        this.props.register(this.state.form, this.handleCancelClick);
      }
    } else {
      NotificationManager.warning(
        strings.get("Client.notifications.cookieRequiredOnregister")
      );
      this.props.setCurrentModal(viewModals.COOKIE_CONSENT);
    }
  };

  handleCancelClick = () => {
    this.refs.registerModal.hide();
    this.refs.loginModal.hide();
    this.refs.forgotModal.hide();
    this.refs.resetPasswordModal.hide();
  };

  getLoginMessageModal = () => {
    return (
      <MessageToLogIn
        profile={this.props.profile}
        handleLoginClick={this.handleLoginClick}
        handleRegisterClick={this.handleRegisterClick}
        currentModal={this.props.currentModal}
        setCurrentModal={this.props.setCurrentModal}
      />
    );
  };

  render() {
    return (
      <div
        className="Navbar"
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        {this.getCookieConsentModal()}
        {this.getLoginModal()}
        {this.getRegisterModal()}
        {this.getForgotModal()}
        {this.getResetPasswordModal()}
        {this.getLoginMessageModal()}
        {this.getMenu()}
        {this.getMenuUser()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
    profile: authSelectors.getProfile(state),
    headerArticleCategories: categoriesSelectors.getHeaderArticleCategories(
      state
    ),
    siteSettings: settingsSelectors.getSiteSettings(state),
    currentModal: settingsSelectors.getCurrentModal(state),
    cookieBannerStatus: settingsSelectors.getCookieBannerStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (data, handleOtpSent) => {
      dispatch(authActions.login(data, handleOtpSent));
    },
    otpSubmit: (data) => {
      dispatch(authActions.otpSubmit(data));
    },
    register: (data, callBack = () => {}) => {
      dispatch(authActions.register(data, callBack));
    },
    forgot: (data) => {
      dispatch(authActions.forgot(data));
    },
    resetPassword: (data, callBack=()=>{})=>{
      dispatch(authActions.resetPassword(data))
    },
    logout: () => {
      dispatch(authActions.logout());
    },   
    getLinkedInDetails: (code, callback: Function) => {
      dispatch(authActions.getLinkedInDetails(code, callback))
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    fetchHeaderArticleCategories: () => {
      dispatch(categoriesActions.fetchHeaderArticleCategories());
    },
    setCurrentModal: (data) => {
      dispatch(settingsActions.setCurrentModal(data));
    },
    setCookieBannerStatus: (status) => {
      dispatch(settingsActions.setCookieBanner(status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
